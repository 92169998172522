import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ImgReSize from '../components/imgs/ImgReSize'
import { DiscussionEmbed } from 'disqus-react'

import './i18n';
import { withTranslation } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';

export const disqusConfig = ({ slug, title }) => ({
  shortname: 'mallorcasecrets',  
  config: { identifier: slug, title },
})

export const BlogPostTemplate = ({
  slug,
  content,
  contentComponent,
  description,
  title,
  image,
  helmet,
  
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
    <section>
      <div className="hero is-large">
          <div className="hero-body">
            <figure className="image is-5by1" >
              <ImgReSize filename={image === null ? null : image.relativePath} alt={title} style={{height: '60vh' }}></ImgReSize>
            </figure>
            <div className="is-overlay has-text-centered" style={{paddingTop:'200px'}} > 
                <h1 className="is-size-1 is-spaced has-text-white has-text-weight-bold is-bold-light">
                  {title}
                </h1>
            </div>
          </div>
        </div>
    </section>
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
    <section>
    <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <DiscussionEmbed {...disqusConfig({ slug, title})} /> 
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.object,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.titleseo}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.descriptionseo}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        slug={post.fields.slug}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default withI18next()(withTranslation()(BlogPost));

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        titleseo
        description
        descriptionseo
        image {
          absolutePath
          relativePath
        }
      }
      fields {
        slug
      }
    }
  }
`
